var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-row"},[_c('div',{staticClass:"md:mb-24 flex items-center justify-between mb-16"},[_c('h3',{staticClass:"inline-block",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('router-link',{staticClass:"text-14 md:inline text-blue-dark border-blue-dark hover:border-transparent hidden font-bold leading-none transition-colors border-b",attrs:{"to":{ name: 'products' }}},[_vm._v("Bekijk alle")])],1),(_vm.localProducts != null && _vm.localProducts.length > 0)?_c('carousel',{attrs:{"amount":_vm.localProducts.length,"responsive":{
            0: {
                items: 2,
                gutter: 16,
            },
            768: {
                items: 3,
                gutter: 24,
            },
            1024: {
                items: 4,
                gutter: 24,
            },
            1280: {
                items: 3,
                disabled: true,
                gutter: 24,
            },
            1440: {
                items: 4,
                gutter: 24,
            },
            1680: {
                items: 6,
                gutter: 24,
            },
        }}},_vm._l((_vm.localProducts),function(product){return _c('product-card',{key:product.id,attrs:{"product":product}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }