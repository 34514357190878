
















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        Carousel: () => import('@/components/elements/Carousel.vue'),
        ProductCard: () => import('@/components/product/ProductCard.vue'),
    },
})
export default class ProductRow extends Vue {
    @Prop() title!: string;
    @Prop() products!: Product[];

    localProducts: Product[] = [];

    mounted() {
        this.calculateProducts();
        const self = this;
        window.addEventListener('resize', () => {
            self.calculateProducts();
        });
        window.addEventListener('orientationchange', () => {
            self.calculateProducts();
        });
    }

    calculateProducts() {
        if (window.innerWidth >= 768 && window.innerWidth <= 1023.98) {
            this.localProducts = this.products.slice(0, 3);
        } else if (window.innerWidth >= 1024 && window.innerWidth <= 1679.98) {
            this.localProducts = this.products.slice(0, 4);
        } else {
            this.localProducts = this.products;
        }
    }
}
